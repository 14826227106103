import React from "react"
import { graphql } from "gatsby"

// imported components
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Longcard, Casecard} from "../components/card";

// imported styles
import mainStyles from "../styles/css/main.module.scss"

export default ({ data }) => {
  return (
    <Layout>
      <SEO 
        title="Beginner Design Cases"
        description="Build your experience by designing a real world product"
      />

      <section className={mainStyles.majorSection}>
        <div className={mainStyles.mainPadding}>

        <div className={[mainStyles.mainExplanatoryContent_wide, mainStyles.paddingBottom3].join(' ')}>
          <h1>Beginner Design Cases</h1>
          <p>Build your experience by designing a real world product</p>
        </div>

          {data.allMarkdownRemark.edges.map(({ node }) => (
            <Casecard
              cardName = "Design Case"
              cardImg = {node.frontmatter.featuredImage.childImageSharp.fluid}
              cardTitle = {node.frontmatter.title}
              cardBody = {node.frontmatter.description}
              cardLink = {node.fields.slug}
            />
          ))}

        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {frontmatter: {templateKey: {eq: "cases"}}},
      sort: {fields: [frontmatter___number], order: ASC }
    ) 
      {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            number
            description
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`