import React from "react"
import {Link} from 'gatsby'
import cardStyles from "../styles/css/card.module.scss"
import mainStyles from "../styles/css/main.module.scss"
import Img from "gatsby-image"
/*
available props
-
cardName
cardTitle
cardBody
cardEmoji
cardBackground
cardLink
cardAlt

*/

export const Richcard = props => (
    <article>
        <a className={cardStyles.snippetWithImage} href={props.cardLink} target="_blank" rel="noopener noreferrer">
            <div className={[cardStyles.snippetWithImage_img, props.cardBackground].join(' ')}>
                <img src={props.cardEmoji} alt="Emoji" className={cardStyles.emoji}/>
            </div>

            <div className={cardStyles.snippetWithImage_txt}>
                <div className={[mainStyles.paddingBottom1, mainStyles.smallLabel].join(' ')}>{props.cardName}</div>
                <h4>{props.cardTitle}</h4>
                <p className={mainStyles.small}>{props.cardBody}</p>
            </div>
        </a>
    </article>
)

export const RichcardLink = props => (
    <article>
        <Link className={cardStyles.snippetWithImage} to={props.cardLink}>
            <div className={[cardStyles.snippetWithImage_img, props.cardBackground].join(' ')}>
                <img src={props.cardEmoji} alt="Emoji" className={cardStyles.emoji}/>
            </div>

            <div className={cardStyles.snippetWithImage_txt}>
                <div className={[mainStyles.paddingBottom1, mainStyles.smallLabel].join(' ')}>{props.cardName}</div>
                <h4>{props.cardTitle}</h4>
                <p className={mainStyles.small}>{props.cardBody}</p>
            </div>
        </Link>
    </article>
)

export const Longcard = props => (
    <article>
        <Link className={cardStyles.snippetWithImage} to={props.cardPage} style={{color: "black"}}>
            <div className={cardStyles.snippetWithImage_longtxt}>
                <div className={[mainStyles.paddingBottom1, mainStyles.smallLabel].join(' ')}>{props.cardName}</div>
                <h4>{props.cardTitle}</h4>
                <p className={mainStyles.small}>{props.cardBody}</p>
            </div>
        </Link>
    </article>
)

export const Casecard = props => (
    <Link className={cardStyles.snippetWithImage} to={props.cardLink}>
        <Img fluid={props.cardImg} className={cardStyles.snippetWithImage_img}/>
    
        <div className={cardStyles.snippetWithImage_txt}>
            <div className={[mainStyles.paddingBottom1, mainStyles.smallLabel].join(' ')}>{props.cardName}</div>
            <h4>{props.cardTitle}</h4>
            <p className={mainStyles.small}>{props.cardBody}</p>
        </div>
    </Link>
)
